.label {
  position: relative;
  padding: 16px 12px 2px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--gray60_100);
}

.icon {
  margin-right: 12px;
  display: inline-block;
  width: 16px;
  height: 16px;
}
