.subtitles {
  margin-top: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.list ul {
  margin: 4px 0 16px;
  padding: 0;
  list-style: none;
}

.list li {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  color: var(--color_gray_4, #7a7a7a);
}

.map-button {
  margin-left: auto;
  justify-self: right;
}
