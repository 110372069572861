.element {
  width: 100%;
  height: 1px;
}

.element-background_color--transparent {
  background-color: transparent;
}
.element-background_color--black {
  background-color: #121212;
}
.element-background_color--white {
  background-color: white;
}
.element-background_color--gray_0 {
  background-color: #f4f4f4;
}
.element-background_color--gray_1 {
  background-color: #e4e4e4;
}
.element-background_color--gray_2 {
  background-color: #c9c9c9;
}
.element-background_color--gray_3 {
  background-color: var(--decorative-subway-msk-serpukhovskaya);
}
.element-background_color--gray_4 {
  background-color: #7a7a7a;
}
.element-background_color--blue {
  background-color: #2b87db;
}
.element-background_color--pink {
  background-color: #e84260;
}
.element-background_color--green {
  background-color: #2e9e00;
}
.element-background_color--red {
  background-color: #ff5050;
}
.element-background_color--orange {
  background-color: #ff9d00;
}
.element-background_color--yellow {
  background-color: var(--surface-warning-default);
}
