.app * {
  box-sizing: border-box;
}

.fake_header {
  position: relative;
}

/* Фикс минимальной ширины страницы при использовании десктопного хедера */
/* stylelint-disable */
:global {
  html {
    min-width: 1024px;
  }
}
/* stylelint-enable */

/* стили для печати */
@media print {
  @page {
    size: auto;
    margin: 1.5cm 1cm;
  }

  .fake_header {
    display: none;
  }
}
/* stylelint-disable */
:global {
  @media print {
    .fake_header {
      display: none;
    }

    [alt='UX Feedback'] {
      display: none !important;
    }

    body {
      display: block;
    }
    header {
      display: none;
    }

    .c-footer-mobile > a {
      display: none;
    }
    .c-footer-mobile:before {
      display: none;
    }

    [data-id='offers_body_container'] {
      border-left-width: 0;
      border-right-width: 0;
    }

    [data-id='gk_card_even'],
    [data-id='gk_card_odd'] {
      border-width: 1px;
      margin-bottom: -1px;
    }
  }
}
/* stylelint-enable */
