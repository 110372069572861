@import '../../styles/common/colors.css';

.container {
  transition: color 0.3s ease-in;
}

.container-display--none {
  display: none;
}
.container-display--block {
  display: block;
}
.container-display--inline {
  display: inline;
}
.container-display--inline-block {
  display: inline-block;
}
.container-display--flex {
  display: flex;
}
.container-display--inline-flex {
  display: inline-flex;
}

.container-size--7 {
  width: 7px;
  height: 7px;
}

.container-size--10 {
  width: 10px;
  height: 10px;
}
.container-size--12 {
  width: 12px;
  height: 12px;
}
.container-size--14 {
  width: 14px;
  height: 14px;
}
.container-size--16 {
  width: 16px;
  height: 16px;
}
.container-size--22 {
  width: 22px;
  height: 22px;
}
.container-size--40 {
  width: 40px;
  height: 40px;
}
.container-size--48 {
  width: 48px;
  height: 48px;
}

.icon-color--transparent {
  color: transparent;
}
.icon-color--inherit {
  color: inherit;
}
.icon-color--black {
  color: var(--color_black);
}
.icon-color--white {
  color: var(--color_white);
}
.icon-color--gray_0 {
  color: var(--color_gray_0);
}
.icon-color--gray_1 {
  color: var(--color_gray_1);
}
.icon-color--gray_2 {
  color: var(--color_gray_2);
}
.icon-color--gray_3 {
  color: var(--color_gray_3);
}
.icon-color--gray_4 {
  color: var(--color_gray_4);
}
.icon-color--gray_6 {
  color: var(--color_gray_6);
}
.icon-color--blue {
  color: var(--color_blue);
}
.icon-color--blue_hover {
  color: var(--color_blue_hover);
}
.icon-color--pink {
  color: var(--color_pink);
}
.icon-color--green {
  color: var(--color_green);
}
.icon-color--green_darken {
  color: var(--color_green_darken);
}
.icon-color--red {
  color: var(--color_red);
}
.icon-color--red_1 {
  color: var(--color_red_1);
}
.icon-color--orange {
  color: var(--color_orange);
}
.icon-color--yellow {
  color: var(--color_yellow);
}

.icon-color_hovered--inherit:hover {
  color: inherit;
}
.icon-color_hovered--transparent:hover {
  color: transparent;
}
.icon-color_hovered--black:hover {
  color: var(--color_black);
}
.icon-color_hovered--white:hover {
  color: var(--color_white);
}
.icon-color_hovered--gray_0:hover {
  color: var(--color_gray_0);
}
.icon-color_hovered--gray_1:hover {
  color: var(--color_gray_1);
}
.icon-color_hovered--gray_2:hover {
  color: var(--color_gray_2);
}
.icon-color_hovered--gray_3:hover {
  color: var(--color_gray_3);
}
.icon-color_hovered--gray_4:hover {
  color: var(--color_gray_4);
}
.icon-color_hovered--blue:hover {
  color: var(--color_blue);
}
.icon-color_hovered--blue_hover:hover {
  color: var(--color_blue_hover);
}
.icon-color_hovered--pink:hover {
  color: var(--color_pink);
}
.icon-color_hovered--green:hover {
  color: var(--color_green);
}
.icon-color_hovered--green_darken:hover {
  color: var(--color_green_darken);
}
.icon-color_hovered--red:hover {
  color: var(--color_red);
}
.icon-color_hovered--red_1:hover {
  color: var(--color_red_1);
}
.icon-color_hovered--orange:hover {
  color: var(--color_orange);
}
.icon-color_hovered--yellow:hover {
  color: var(--color_yellow);
}

.icon {
  display: flex;
}

.icon,
.icon svg {
  width: 100%;
  height: 100%;
}

.icon svg {
  display: flex;
  place-content: center;
  align-items: center;
  fill: currentcolor;
  vertical-align: top;
  pointer-events: none;
}

.icon-metro {
  width: 16px;
  height: 16px;
}

.icon-metro::before {
  margin: 6px 4px 2px;
  display: inline-block;
  width: 8px;
  height: 8px;
  content: '';
  background-color: currentcolor;
  border-radius: 8px;
}

.icon-type--loader {
  width: 100%;
  height: 100%;
  font-size: 18%;
  border: 1em solid currentcolor;
  border-top: 1em solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* MOBILE */
@media (max-width: 991px) {
  .container-size_mobile--7 {
    width: 7px;
    height: 7px;
  }
  .container-size_mobile--10 {
    width: 10px;
    height: 10px;
  }
  .container-size_mobile--12 {
    width: 12px;
    height: 12px;
  }
  .container-size_mobile--14 {
    width: 14px;
    height: 14px;
  }
  .container-size_mobile--16 {
    width: 16px;
    height: 16px;
  }
  .container-size_mobile--40 {
    width: 40px;
    height: 40px;
  }
}

/* DESKTOP */
@media (min-width: 992px) {
  .container-size_desktop--7 {
    width: 7px;
    height: 7px;
  }
  .container-size_desktop--10 {
    width: 10px;
    height: 10px;
  }
  .container-size_desktop--12 {
    width: 12px;
    height: 12px;
  }
  .container-size_desktop--14 {
    width: 14px;
    height: 14px;
  }
  .container-size_desktop--16 {
    width: 16px;
    height: 16px;
  }
  .container-size_desktop--40 {
    width: 40px;
    height: 40px;
  }
}
