@import '../../styles/common/colors.css';

.image--simple,
.image--simple::before {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-color: var(--color_gray_0);
}

.image--simple::before {
  content: ' ';
}

.image--empty {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: var(--color_gray_0);
}
