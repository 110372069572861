.labels {
  position: absolute;
  z-index: 1;
  top: var(--unit_2);
  left: var(--unit_2);
}

.promo {
  top: var(--unit_6);
  left: var(--unit_6);
}
