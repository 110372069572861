.container {
  display: flex;
  height: 220px;
  gap: 24px;
  align-items: center;
}

.image {
  padding: 10px;
}

.logo {
  width: 170px;
  height: 153px;
  background-image: url('./assets/notAvailable.svg');
}

.controls {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.description {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
