.marked_list_wrapper ul {
  margin: 0 0 10px;
  padding: 0;
  list-style-type: none;

  li {
    margin-right: 12px;
    display: inline;
    line-height: normal;
    color: var(--color_gray_4);

    &:not(:first-child)::before {
      position: relative;
      top: -2px;
      padding-right: 8px;
      font-size: 6px;
      content: '\2022';
    }
  }
}
