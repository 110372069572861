.layout {
  position: relative;
  z-index: 0;
  margin: 0 calc(-1 * var(--unit_5));
  padding: var(--unit_5);
  display: flex;
  justify-content: space-between;
  background: var(--decorative-theme-white);

  &:hover {
    z-index: 2;
    padding: var(--unit_5);
    border-radius: var(--unit_2);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);

    &::after {
      display: none;
    }
  }

  &::after {
    position: absolute;
    z-index: 1;
    right: var(--unit_5);
    left: var(--unit_5);
    bottom: -1px;
    height: 1px;
    content: '';
    background: var(--gray10_100);
  }
}

@media print {
  .layout {
    page-break-inside: avoid;
  }
}
