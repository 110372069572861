.phone {
  padding: 8px 16px;
  display: flex;
  min-width: 150px;
  height: 28px;
  background: var(--solid-gray50, #f3f6ff);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
