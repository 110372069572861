.container {
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1376px;
  background-color: white;
  border: 1px solid #e4e4e4;
  border-top: 0;
}

.is-visible {
  padding: 40px 20px;
}
