:root {
  --color_black: #121212;
  --color_white: white;
  --color_gray_0: #f4f4f4;
  --color_gray_1: #e4e4e4;
  --color_gray_2: #c9c9c9;
  --color_gray_3: #999;
  --color_gray_4: #7a7a7a;
  --color_gray_5: #e8e9ec;
  --color_gray_6: #737a8e;
  --color_blue: #2b87db;
  --color_blue_2: var(--accent-main-primary);
  --color_pink: #e84260;
  --color_green: #2e9e00;
  --color_green_darken: #257f00;
  --color_red: #ff5050;
  --color_red_1: #da2f2f;
  --color_red_2: #ea4f6b;
  --color_orange: #ff9d00;
  --color_yellow: var(--surface-warning-default);

  /* Пока нет в ui kit */
  --color_blue_hover: #256ba5;
  --color_blue_light: #95c3ed;
  --color_blue_light_1: #e9f3fb;
  --color_blue_light_2: rgba(43, 135, 219, 0.1);
  --color_green_light: rgba(46, 158, 0, 0.07);

  /* Чёрные стили для контроллов */
  --color_dark_light_1: #e9f3fb;

  /* MODAL */
  --modal_color_background: rgba(55, 55, 55, 0.6);
  --modal_color_shadow: rgba(0, 0, 0, 0.25);
}
