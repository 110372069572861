@import '../../styles/common/colors.css';

.container {
  padding: 16px 20px;
  background-color: var(--decorative-theme-white);
  border: 1px solid var(--color_gray_1);
  border-top: 0;
  /*
   Для SEO этот элемент в коде расположен рядом с заголовком, но отображается в самом низу страницы
   Переместить компонент вниз и удалить этот параметр, если задуманное в https://jira.cian.tech/browse/OM-910
   не будет реализовано
  */
  grid-row-end: 99;
}
