.container {
  padding: 20px 24px 20px 0;
  display: flex;
  align-items: center;
  gap: 20px;
  background: var(--surface-neutral-default);
  border-radius: 8px;
}

.image {
  width: 82px;
  height: 46px;
  background: url('./assets/blackFriday.svg') center no-repeat;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
