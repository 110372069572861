@import '../../styles/common/colors.css';

.field {
  position: relative;
  display: flex;
}

.label {
  margin: 0;
  padding: 4px 0 6px;
}

.stack {
  padding-left: 12px;
  display: flex;
  width: 100%;
}
