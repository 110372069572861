.container {
  margin: 16px 0;
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  background:
    url('./assets/cards.png') max(400px, 100%) 0 no-repeat,
    var(--primary_100);
  background-size: contain;
  border-radius: 8px;
}

.description-wrapper {
  margin: 8px 0 16px;
}
