.container {
  margin: 0 auto;
  padding-right: 20px;
  padding-left: 20px;
  box-sizing: border-box;
  min-width: 704px;
  max-width: 1376px;
}

.container-display--none {
  display: none;
}

.container-display--block {
  display: block;
}

@media (max-width: 991px) {
  .container {
    padding-right: 12px;
    padding-left: 12px;
  }

  .container-display_mobile--none {
    display: none;
  }

  .container-display_mobile--block {
    display: block;
  }
}

@media (max-width: 639px) {
  .container-display_phone--none {
    display: none;
  }

  .container-display_phone--block {
    display: block;
  }
}

@media (min-width: 640px) and (min-width: 991px) {
  .container-display_tablet--none {
    display: none;
  }

  .container-display_tablet--block {
    display: block;
  }
}

@media (min-width: 1232px) {
  .container-display_desktop_max--none {
    display: none;
  }
}

@media print {
  .container {
    margin: 0 1px;
    padding: 0;
    max-width: 100%;
  }
}
