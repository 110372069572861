.label {
  margin-right: 8px;
  display: inline-block;

  & > span {
    padding-top: 4px;
    padding-bottom: 4px;
    height: inherit;
    font-weight: normal;
  }
}
