.container {
  position: relative;
  width: 272px;
  height: 204px;
  flex: none;
}

.features {
  position: absolute;
  z-index: 3;
  left: 8px;
  bottom: 8px;
}

.logo {
  position: absolute;
  z-index: 3;
  top: 24px;
  right: 24px;
}

.is-promo {
  width: 617px;
  height: 100%;

  @media (max-width: 1340px) {
    width: 576px;
  }

  @media (max-width: 1240px) {
    width: 526px;
  }

  @media (max-width: 1170px) {
    width: 486px;
  }

  .promos {
    top: 24px;
    left: 24px;
  }

  .features {
    left: 24px;
    bottom: 24px;
  }
}

@media print {
  .features {
    display: none;
  }
}
