@import '../../styles/common/colors.css';

.tag {
  padding: 0;
  display: flex;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-radius: 4px;
}

.tag:focus {
  outline: 0;
  box-shadow: 0 0 0 3px color(var(--color_blue) a(50%));
}

.tag-content {
  padding: 3px 8px 5px 16px;
  display: inline-block;
  background-color: var(--color_gray_0);
  border-radius: 4px 0 0 4px;
}

.tag-icon_close,
.tag-icon_up,
.tag-icon_down {
  position: relative;
  display: inline-block;
  min-height: 28px;
  background-color: var(--color_gray_0);
  border-radius: 0 4px 4px 0;
}

.tag-icon_close {
  position: relative;
  padding: 3px 8px 5px 16px;
  cursor: pointer;
  background-color: var(--color_gray_0);
  border-radius: 0 4px 4px 0;
}

.tag-icon_close::before,
.tag-icon_close::after {
  position: absolute;
  top: 10px;
  right: 18px;
  width: 2px;
  height: 9px;
  content: ' ';
  background-color: var(--color_gray_2);
  border-radius: 1px;
}

.tag-icon_close::before {
  transform: rotate(45deg);
}

.tag-icon_close::after {
  transform: rotate(-45deg);
}

.tag:hover .tag-icon_close::before,
.tag:hover .tag-icon_close::after {
  background-color: var(--color_gray_4);
}

.tag-icon_up,
.tag-icon_down {
  width: 24px;
}

.tag-icon_up::after,
.tag-icon_down::after {
  position: absolute;
  top: 9px;
  right: 16px;
  width: 6px;
  height: 6px;
  content: ' ';
  border-top: 2px solid var(--color_gray_2);
  border-left: 2px solid var(--color_gray_2);
  transform: rotate(-135deg);
}

.tag-icon_down::after {
  top: 13px;
  transform: rotate(45deg);
}

.tag:hover .tag-icon_up::after,
.tag:hover .tag-icon_down::after {
  border-color: var(--color_gray_4);
}

.tag--disabled,
.tag--disabled .tag-icon_close {
  cursor: not-allowed;
}

.tag--disabled .tag-content {
  background-color: var(--color_gray_0);
}

.tag--disabled .tag-icon_close::after,
.tag--disabled:hover .tag-icon_close::after,
.tag--disabled .tag-icon_close::before,
.tag--disabled:hover .tag-icon_close::before {
  background-color: var(--color_gray_1);
}

.tag--disabled .tag-icon_up::after,
.tag--disabled:hover .tag-icon_up::after,
.tag--disabled .tag-icon_down::after,
.tag--disabled:hover .tag-icon_down::after {
  border-color: var(--color_gray_1);
}
