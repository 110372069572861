@import '../../styles/common/colors.css';

.crumb {
  font-size: 14px;
  line-height: 1.43;
  cursor: pointer;
  color: var(--gray60_100);
}

.crumb:not(:last-child)::after {
  position: relative;
  margin: 0 6px;
  display: inline-block;
  content: '/';
}

.crumb:hover {
  color: var(--black_60);
}
