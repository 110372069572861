@import '../../../styles/common/colors.css';

/* list */
.container {
  margin: 0;
  padding: 7px 0;
  list-style: none;
}

.item {
  padding: 5px 32px 7px 12px;
  display: flex;
  align-items: center;
  line-height: 20px;
  cursor: pointer;
  background-color: var(--color_white);
}

.is-subtitle {
  cursor: default;
}

.item:not(.is-subtitle):hover {
  background-color: var(--color_blue_light_1);
}

.icon {
  margin-right: 6px;
}

.icon--hidden {
  margin-right: 20px;
}
