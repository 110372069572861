.element {
  display: inline-block;
  cursor: pointer;
  color: var(--decorative-theme-dark);
  transition: color 0.1s ease-in;
}

.element:hover {
  color: var(--control-main-primary-hovered);
}
