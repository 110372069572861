.preloader {
  position: fixed;
  inset: 0;
  margin: auto;
  width: 50px;
  height: 50px;
}

.preloader_overlay {
  position: absolute;
  z-index: 100;
  inset: 1px 0 0;
  background-color: rgba(255, 255, 255, 0.6);
  opacity: 1;
  transition: opacity 0.3s linear;
  will-change: opacity;
}
