@import '../../styles/common/colors.css';

.container {
  border-width: 1px;
}

.container-clearfix::after {
  display: table;
  clear: both;
  content: '';
}

.container--shadow {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}

.container-background_color--transparent {
  background-color: transparent;
}
.container-background_color--black {
  background-color: var(--color_black);
}
.container-background_color--white {
  background-color: var(--color_white);
}
.container-background_color--gray_0 {
  background-color: var(--color_gray_0);
}
.container-background_color--gray_1 {
  background-color: var(--color_gray_1);
}
.container-background_color--gray_2 {
  background-color: var(--color_gray_2);
}
.container-background_color--gray_3 {
  background-color: var(--color_gray_3);
}
.container-background_color--gray_4 {
  background-color: var(--color_gray_4);
}
.container-background_color--gray_5 {
  background-color: var(--color_gray_5);
}
.container-background_color--blue {
  background-color: var(--color_blue);
}
.container-background_color--blue_light_1 {
  background-color: var(--color_blue_light_1);
}
.container-background_color--blue_light_2 {
  background-color: var(--color_blue_light_2);
}
.container-background_color--pink {
  background-color: var(--color_pink);
}
.container-background_color--green {
  background-color: var(--color_green);
}
.container-background_color--green_light {
  background-color: var(--color_green_light);
}
.container-background_color--red {
  background-color: var(--color_red);
}
.container-background_color--red_1 {
  background-color: var(--color_red_1);
}
.container-background_color--red_2 {
  background-color: var(--color_red_2);
}
.container-background_color--orange {
  background-color: var(--color_orange);
}
.container-background_color--yellow {
  background-color: var(--color_yellow);
}

.container-background_color_hover--transparent:hover {
  background-color: transparent;
}
.container-background_color_hover--black:hover {
  background-color: var(--color_black);
}
.container-background_color_hover--white:hover {
  background-color: var(--color_white);
}
.container-background_color_hover--gray_0:hover {
  background-color: var(--color_gray_0);
}
.container-background_color_hover--gray_1:hover {
  background-color: var(--color_gray_1);
}
.container-background_color_hover--gray_2:hover {
  background-color: var(--color_gray_2);
}
.container-background_color_hover--gray_3:hover {
  background-color: var(--color_gray_3);
}
.container-background_color_hover--gray_4:hover {
  background-color: var(--color_gray_4);
}
.container-background_color_hover--gray_5:hover {
  background-color: var(--color_gray_5);
}
.container-background_color_hover--blue:hover {
  background-color: var(--color_blue);
}
.container-background_color_hover--blue_hover:hover {
  background-color: var(--color_blue_light_1);
}
.container-background_color_hover--pink:hover {
  background-color: var(--color_pink);
}
.container-background_color_hover--green:hover {
  background-color: var(--color_green);
}
.container-background_color_hover--red:hover {
  background-color: var(--color_red);
}
.container-background_color_hover--orange:hover {
  background-color: var(--color_orange);
}
.container-background_color_hover--yellow:hover {
  background-color: var(--color_yellow);
}

.container-border_color--transparent {
  border-color: transparent;
}
.container-border_color--black {
  border-color: var(--color_black);
}
.container-border_color--white {
  border-color: var(--color_white);
}
.container-border_color--gray_0 {
  border-color: var(--color_gray_0);
}
.container-border_color--gray_1 {
  border-color: var(--color_gray_1);
}
.container-border_color--gray_2 {
  border-color: var(--color_gray_2);
}
.container-border_color--gray_3 {
  border-color: var(--color_gray_3);
}
.container-border_color--gray_4 {
  border-color: var(--color_gray_4);
}
.container-border_color--gray_5 {
  border-color: var(--color_gray_5);
}
.container-border_color--blue {
  border-color: var(--color_blue);
}
.container-border_color--blue_hover {
  border-color: var(--color_blue_hover);
}
.container-border_color--pink {
  border-color: var(--color_pink);
}
.container-border_color--green {
  border-color: var(--color_green);
}
.container-border_color--red {
  border-color: var(--color_red);
}
.container-border_color--orange {
  border-color: var(--color_orange);
}
.container-border_color--yellow {
  border-color: var(--color_yellow);
}

.container-border_color_hover--transparent:hover {
  border-color: transparent;
}
.container-border_color_hover--black:hover {
  border-color: var(--color_black);
}
.container-border_color_hover--white:hover {
  border-color: var(--color_white);
}
.container-border_color_hover--gray_0:hover {
  border-color: var(--color_gray_0);
}
.container-border_color_hover--gray_1:hover {
  border-color: var(--color_gray_1);
}
.container-border_color_hover--gray_2:hover {
  border-color: var(--color_gray_2);
}
.container-border_color_hover--gray_3:hover {
  border-color: var(--color_gray_3);
}
.container-border_color_hover--gray_4:hover {
  border-color: var(--color_gray_4);
}
.container-border_color_hover--gray_5:hover {
  border-color: var(--color_gray_5);
}
.container-border_color_hover--blue:hover {
  border-color: var(--color_blue);
}
.container-border_color_hover--blue_hover:hover {
  border-color: var(--color_blue_hover);
}
.container-border_color_hover--pink:hover {
  border-color: var(--color_pink);
}
.container-border_color_hover--green:hover {
  border-color: var(--color_green);
}
.container-border_color_hover--red:hover {
  border-color: var(--color_red);
}
.container-border_color_hover--orange:hover {
  border-color: var(--color_orange);
}
.container-border_color_hover--yellow:hover {
  border-color: var(--color_yellow);
}

.container-position--absolute {
  position: absolute;
}
.container-position--fixed {
  position: fixed;
}
.container-position--relative {
  position: relative;
}
.container-position--static {
  position: static;
}
.container-position--inherit {
  position: inherit;
}

.container-align_items--inherit {
  align-items: inherit;
}
.container-align_items--stretch {
  align-items: stretch;
}
.container-align_items--center {
  align-items: center;
}
.container-align_items--flex-start {
  align-items: flex-start;
}
.container-align_items--flex-end {
  align-items: flex-end;
}
.container-align_items--baseline {
  align-items: baseline;
}

.container-align_content--inherit {
  align-content: inherit;
}
.container-align_content--stretch {
  align-content: stretch;
}
.container-align_content--center {
  align-content: center;
}
.container-align_content--flex-start {
  align-content: flex-start;
}
.container-align_content--flex-end {
  align-content: flex-end;
}
.container-align_content--space-between {
  align-content: space-between;
}
.container-align_content--space-around {
  align-content: space-around;
}

.container-align_self--inherit {
  align-self: inherit;
}
.container-align_self--stretch {
  align-self: stretch;
}
.container-align_self--center {
  align-self: center;
}
.container-align_self--flex-start {
  align-self: flex-start;
}
.container-align_self--baseline {
  align-self: baseline;
}
.container-align_self--auto {
  align-self: auto;
}

.container-justify_content--inherit {
  justify-content: inherit;
}
.container-justify_content--stretch {
  justify-content: stretch;
}
.container-justify_content--center {
  justify-content: center;
}
.container-justify_content--flex-start {
  justify-content: flex-start;
}
.container-justify_content--flex-end {
  justify-content: flex-end;
}
.container-justify_content--baseline {
  justify-content: baseline;
}
.container-justify_content--space-between {
  justify-content: space-between;
}
.container-justify_content--space-around {
  justify-content: space-around;
}
.container-justify_content--space-evenly {
  justify-content: space-evenly;
}

.container-flex_wrap--inherit {
  flex-wrap: inherit;
}
.container-flex_wrap--nowrap {
  flex-wrap: nowrap;
}
.container-flex_wrap--wrap {
  flex-wrap: wrap;
}
.container-flex_wrap--wrap-reverse {
  flex-wrap: wrap-reverse;
}

.container-flex_direction--inherit {
  flex-direction: inherit;
}
.container-flex_direction--row {
  flex-direction: row;
}
.container-flex_direction--row-reverse {
  flex-direction: row-reverse;
}
.container-flex_direction--column {
  flex-direction: column;
}
.container-flex_direction--column-reverse {
  flex-direction: column-reverse;
}

.container-display--none {
  display: none;
}
.container-display--block {
  display: block;
}
.container-display--inline {
  display: inline;
}
.container-display--inline-block {
  display: inline-block;
}
.container-display--flex {
  display: flex;
}
.container-display--inline-flex {
  display: inline-flex;
}
.container-display--table-row {
  display: table-row;
}
.container-display--table-cell {
  display: table-cell;
}
.container-display--table {
  display: table;
}
.container-display--inline-table {
  display: inline-table;
}

.container-border_radius--round {
  border-radius: 50%;
}
.container-border_radius--0 {
  border-radius: 0;
}
.container-border_radius--1 {
  border-radius: 1px;
}
.container-border_radius--2 {
  border-radius: 2px;
}
.container-border_radius--3 {
  border-radius: 3px;
}
.container-border_radius--4 {
  border-radius: 4px;
}
.container-border_radius--5 {
  border-radius: 5px;
}
.container-border_radius--6 {
  border-radius: 6px;
}
.container-border_radius--7 {
  border-radius: 7px;
}
.container-border_radius--8 {
  border-radius: 8px;
}
.container-border_radius--9 {
  border-radius: 9px;
}
.container-border_radius--10 {
  border-radius: 10px;
}
.container-border_radius--11 {
  border-radius: 11px;
}
.container-border_radius--12 {
  border-radius: 12px;
}
.container-border_radius--42 {
  border-radius: 42px;
}

.container-border_width--0 {
  border-width: 0;
}
.container-border_width--1 {
  border-width: 1px;
}
.container-border_width--2 {
  border-width: 2px;
}
.container-border_width--3 {
  border-width: 3px;
}
.container-border_width--4 {
  border-width: 4px;
}

.container-border_top_width--0 {
  border-top-width: 0;
}
.container-border_top_width--1 {
  border-top-width: 1px;
}
.container-border_top_width--2 {
  border-top-width: 2px;
}
.container-border_top_width--3 {
  border-top-width: 3px;
}
.container-border_top_width--4 {
  border-top-width: 4px;
}

.container-border_bottom_width--0 {
  border-bottom-width: 0;
}
.container-border_bottom_width--1 {
  border-bottom-width: 1px;
}
.container-border_bottom_width--2 {
  border-bottom-width: 2px;
}
.container-border_bottom_width--3 {
  border-bottom-width: 3px;
}
.container-border_bottom_width--4 {
  border-bottom-width: 4px;
}

.container-border_left_width--0 {
  border-left-width: 0;
}
.container-border_left_width--1 {
  border-left-width: 1px;
}
.container-border_left_width--2 {
  border-left-width: 2px;
}
.container-border_left_width--3 {
  border-left-width: 3px;
}
.container-border_left_width--4 {
  border-left-width: 4px;
}

.container-border_right_width--0 {
  border-right-width: 0;
}
.container-border_right_width--1 {
  border-right-width: 1px;
}
.container-border_right_width--2 {
  border-right-width: 2px;
}
.container-border_right_width--3 {
  border-right-width: 3px;
}
.container-border_right_width--4 {
  border-right-width: 4px;
}

.container-float--left {
  float: left;
}
.container-float--right {
  float: right;
}
.container-float--none {
  float: none;
}
.container-float--unset {
  float: unset;
}
.container-float--inherit {
  float: inherit;
}
.container-float--initial {
  float: initial;
}

.container-white_space--nowrap {
  white-space: nowrap;
}
.container-white_space--inherit {
  white-space: inherit;
}
.container-white_space--initial {
  white-space: initial;
}
.container-white_space--normal {
  white-space: normal;
}
.container-white_space--pre {
  white-space: pre;
}
.container-white_space--pre-line {
  white-space: pre-line;
}
.container-white_space--pre-wrap {
  white-space: pre-wrap;
}
.container-white_space--revert {
  white-space: revert;
}
.container-white_space--unset {
  white-space: unset;
}

.container-z_index--0 {
  z-index: 0;
}
.container-z_index--1 {
  z-index: 1;
}
.container-z_index--2 {
  z-index: 2;
}
.container-z_index--3 {
  z-index: 3;
}
.container-z_index--4 {
  z-index: 4;
}
.container-z_index--5 {
  z-index: 5;
}
.container-z_index--6 {
  z-index: 6;
}
.container-z_index--7 {
  z-index: 7;
}
.container-z_index--8 {
  z-index: 8;
}
.container-z_index--9 {
  z-index: 9;
}
.container-z_index--10 {
  z-index: 10;
}

.container-vertical_align--middle {
  vertical-align: middle;
}
.container-vertical_align--bottom {
  vertical-align: bottom;
}
.container-vertical_align--top {
  vertical-align: top;
}

.container-visibility--visible {
  visibility: visible;
}
.container-visibility--hidden {
  visibility: hidden;
}
.container-visibility--inherit {
  visibility: inherit;
}

.container-overflow--inherit {
  overflow: inherit;
}
.container-overflow--auto {
  overflow: auto;
}
.container-overflow--visible {
  overflow: visible;
}
.container-overflow--hidden {
  overflow: hidden;
}
.container-overflow--scroll {
  overflow: scroll;
}

.container-border_box {
  box-sizing: border-box;
}

.container-acceleration {
  transform: translateZ(0);
}

@media (max-width: 991px) {
  .container-align_items_mobile--inherit {
    align-items: inherit;
  }
  .container-align_items_mobile--stretch {
    align-items: stretch;
  }
  .container-align_items_mobile--center {
    align-items: center;
  }
  .container-align_items_mobile--flex-start {
    align-items: flex-start;
  }
  .container-align_items_mobile--flex-end {
    align-items: flex-end;
  }
  .container-align_items_mobile--baseline {
    align-items: baseline;
  }

  .container-align_content_mobile--inherit {
    align-content: inherit;
  }
  .container-align_content_mobile--stretch {
    align-content: stretch;
  }
  .container-align_content_mobile--center {
    align-content: center;
  }
  .container-align_content_mobile--flex-start {
    align-content: flex-start;
  }
  .container-align_content_mobile--flex-end {
    align-content: flex-end;
  }
  .container-align_content_mobile--space-between {
    align-content: space-between;
  }
  .container-align_content_mobile--space-around {
    align-content: space-around;
  }

  .container-white_space_mobile--nowrap {
    white-space: nowrap;
  }
  .container-white_space_mobile--inherit {
    white-space: inherit;
  }
  .container-white_space_mobile--initial {
    white-space: initial;
  }
  .container-white_space_mobile--normal {
    white-space: normal;
  }
  .container-white_space_mobile--pre {
    white-space: pre;
  }
  .container-white_space_mobile--pre-line {
    white-space: pre-line;
  }
  .container-white_space_mobile--pre-wrap {
    white-space: pre-wrap;
  }
  .container-white_space_mobile--revert {
    white-space: revert;
  }
  .container-white_space_mobile--unset {
    white-space: unset;
  }

  .container-align_self_mobile--inherit {
    align-self: inherit;
  }
  .container-align_self_mobile--stretch {
    align-self: stretch;
  }
  .container-align_self_mobile--center {
    align-self: center;
  }
  .container-align_self_mobile--flex-start {
    align-self: flex-start;
  }
  .container-align_self_mobile--baseline {
    align-self: baseline;
  }
  .container-align_self_mobile--auto {
    align-self: auto;
  }

  .container-justify_content_mobile--inherit {
    justify-content: inherit;
  }
  .container-justify_content_mobile--stretch {
    justify-content: stretch;
  }
  .container-justify_content_mobile--center {
    justify-content: center;
  }
  .container-justify_content_mobile--flex-start {
    justify-content: flex-start;
  }
  .container-justify_content_mobile--flex-end {
    justify-content: flex-end;
  }
  .container-justify_content_mobile--baseline {
    justify-content: baseline;
  }
  .container-justify_content_mobile--space-between {
    justify-content: space-between;
  }
  .container-justify_content_mobile--space-around {
    justify-content: space-around;
  }
  .container-justify_content_mobile--space-evenly {
    justify-content: space-evenly;
  }

  .container-flex_wrap_mobile--inherit {
    flex-wrap: inherit;
  }
  .container-flex_wrap_mobile--nowrap {
    flex-wrap: nowrap;
  }
  .container-flex_wrap_mobile--wrap {
    flex-wrap: wrap;
  }
  .container-flex_wrap_mobile--wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .container-flex_direction_mobile--inherit {
    flex-direction: inherit;
  }
  .container-flex_direction_mobile--row {
    flex-direction: row;
  }
  .container-flex_direction_mobile--row-reverse {
    flex-direction: row-reverse;
  }
  .container-flex_direction_mobile--column-reverse {
    flex-direction: column;
  }
  .container-flex_direction_mobile--column-reverse {
    flex-direction: column-reverse;
  }

  .container-display_mobile--none {
    display: none;
  }
  .container-display_mobile--block {
    display: block;
  }
  .container-display_mobile--inline {
    display: inline;
  }
  .container-display_mobile--inline-block {
    display: inline-block;
  }
  .container-display_mobile--flex {
    display: flex;
  }
  .container-display_mobile--inline-flex {
    display: inline-flex;
  }
  .container-display_mobile--table-cell {
    display: table-cell;
  }
  .container-display_mobile--table {
    display: table;
  }
  .container-display_mobile--inline-table {
    display: inline-table;
  }
}

@media (max-width: 639px) {
  .container-align_items_phone--inherit {
    align-items: inherit;
  }
  .container-align_items_phone--stretch {
    align-items: stretch;
  }
  .container-align_items_phone--center {
    align-items: center;
  }
  .container-align_items_phone--flex-start {
    align-items: flex-start;
  }
  .container-align_items_phone--flex-end {
    align-items: flex-end;
  }
  .container-align_items_phone--baseline {
    align-items: baseline;
  }

  .container-align_content_phone--inherit {
    align-content: inherit;
  }
  .container-align_content_phone--stretch {
    align-content: stretch;
  }
  .container-align_content_phone--center {
    align-content: center;
  }
  .container-align_content_phone--flex-start {
    align-content: flex-start;
  }
  .container-align_content_phone--flex-end {
    align-content: flex-end;
  }
  .container-align_content_phone--space-between {
    align-content: space-between;
  }
  .container-align_content_phone--space-around {
    align-content: space-around;
  }

  .container-align_self_phone--inherit {
    align-self: inherit;
  }
  .container-align_self_phone--stretch {
    align-self: stretch;
  }
  .container-align_self_phone--center {
    align-self: center;
  }
  .container-align_self_phone--flex-start {
    align-self: flex-start;
  }
  .container-align_self_phone--baseline {
    align-self: baseline;
  }
  .container-align_self_phone--auto {
    align-self: auto;
  }

  .container-white_space_phone--nowrap {
    white-space: nowrap;
  }
  .container-white_space_phone--inherit {
    white-space: inherit;
  }
  .container-white_space_phone--initial {
    white-space: initial;
  }
  .container-white_space_phone--normal {
    white-space: normal;
  }
  .container-white_space_phone--pre {
    white-space: pre;
  }
  .container-white_space_phone--pre-line {
    white-space: pre-line;
  }
  .container-white_space_phone--pre-wrap {
    white-space: pre-wrap;
  }
  .container-white_space_phone--revert {
    white-space: revert;
  }
  .container-white_space_phone--unset {
    white-space: unset;
  }

  .container-justify_content_phone--inherit {
    justify-content: inherit;
  }
  .container-justify_content_phone--stretch {
    justify-content: stretch;
  }
  .container-justify_content_phone--center {
    justify-content: center;
  }
  .container-justify_content_phone--flex-start {
    justify-content: flex-start;
  }
  .container-justify_content_phone--flex-end {
    justify-content: flex-end;
  }
  .container-justify_content_phone--baseline {
    justify-content: baseline;
  }
  .container-justify_content_phone--space-between {
    justify-content: space-between;
  }
  .container-justify_content_phone--space-around {
    justify-content: space-around;
  }
  .container-justify_content_phone--space-evenly {
    justify-content: space-evenly;
  }

  .container-flex_wrap_phone--inherit {
    flex-wrap: inherit;
  }
  .container-flex_wrap_phone--nowrap {
    flex-wrap: nowrap;
  }
  .container-flex_wrap_phone--wrap {
    flex-wrap: wrap;
  }
  .container-flex_wrap_phone--wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .container-flex_direction_phone--inherit {
    flex-direction: inherit;
  }
  .container-flex_direction_phone--row {
    flex-direction: row;
  }
  .container-flex_direction_phone--row-reverse {
    flex-direction: row-reverse;
  }
  .container-flex_direction_phone--column-reverse {
    flex-direction: column;
  }
  .container-flex_direction_phone--column-reverse {
    flex-direction: column-reverse;
  }

  .container-display_phone--none {
    display: none;
  }
  .container-display_phone--block {
    display: block;
  }
  .container-display_phone--inline {
    display: inline;
  }
  .container-display_phone--inline-block {
    display: inline-block;
  }
  .container-display_phone--flex {
    display: flex;
  }
  .container-display_phone--inline-flex {
    display: inline-flex;
  }
  .container-display_phone--table-row {
    display: table-row;
  }
  .container-display_phone--table-cell {
    display: table-cell;
  }
  .container-display_phone--table {
    display: table;
  }
  .container-display_phone--inline-table {
    display: inline-table;
  }
}

@media (min-width: 640px) and (max-width: 991px) {
  .container-align_items_tablet--inherit {
    align-items: inherit;
  }
  .container-align_items_tablet--stretch {
    align-items: stretch;
  }
  .container-align_items_tablet--center {
    align-items: center;
  }
  .container-align_items_tablet--flex-start {
    align-items: flex-start;
  }
  .container-align_items_tablet--flex-end {
    align-items: flex-end;
  }
  .container-align_items_tablet--baseline {
    align-items: baseline;
  }

  .container-align_content_tablet--inherit {
    align-content: inherit;
  }
  .container-align_content_tablet--stretch {
    align-content: stretch;
  }
  .container-align_content_tablet--center {
    align-content: center;
  }
  .container-align_content_tablet--flex-start {
    align-content: flex-start;
  }
  .container-align_content_tablet--flex-end {
    align-content: flex-end;
  }
  .container-align_content_tablet--space-between {
    align-content: space-between;
  }
  .container-align_content_tablet--space-around {
    align-content: space-around;
  }

  .container-align_self_tablet--inherit {
    align-self: inherit;
  }
  .container-align_self_tablet--stretch {
    align-self: stretch;
  }
  .container-align_self_tablet--center {
    align-self: center;
  }
  .container-align_self_tablet--flex-start {
    align-self: flex-start;
  }
  .container-align_self_tablet--baseline {
    align-self: baseline;
  }
  .container-align_self_tablet--auto {
    align-self: auto;
  }

  .container-white_space_tablet--nowrap {
    white-space: nowrap;
  }
  .container-white_space_tablet--inherit {
    white-space: inherit;
  }
  .container-white_space_tablet--initial {
    white-space: initial;
  }
  .container-white_space_tablet--normal {
    white-space: normal;
  }
  .container-white_space_tablet--pre {
    white-space: pre;
  }
  .container-white_space_tablet--pre-line {
    white-space: pre-line;
  }
  .container-white_space_tablet--pre-wrap {
    white-space: pre-wrap;
  }
  .container-white_space_tablet--revert {
    white-space: revert;
  }
  .container-white_space_tablet--unset {
    white-space: unset;
  }

  .container-justify_content_tablet--inherit {
    justify-content: inherit;
  }
  .container-justify_content_tablet--stretch {
    justify-content: stretch;
  }
  .container-justify_content_tablet--center {
    justify-content: center;
  }
  .container-justify_content_tablet--flex-start {
    justify-content: flex-start;
  }
  .container-justify_content_tablet--flex-end {
    justify-content: flex-end;
  }
  .container-justify_content_tablet--baseline {
    justify-content: baseline;
  }
  .container-justify_content_tablet--space-between {
    justify-content: space-between;
  }
  .container-justify_content_tablet--space-around {
    justify-content: space-around;
  }
  .container-justify_content_tablet--space-evenly {
    justify-content: space-evenly;
  }

  .container-flex_wrap_tablet--inherit {
    flex-wrap: inherit;
  }
  .container-flex_wrap_tablet--nowrap {
    flex-wrap: nowrap;
  }
  .container-flex_wrap_tablet--wrap {
    flex-wrap: wrap;
  }
  .container-flex_wrap_tablet--wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .container-flex_direction_tablet--inherit {
    flex-direction: inherit;
  }
  .container-flex_direction_tablet--row {
    flex-direction: row;
  }
  .container-flex_direction_tablet--row-reverse {
    flex-direction: row-reverse;
  }
  .container-flex_direction_tablet--column-reverse {
    flex-direction: column;
  }
  .container-flex_direction_tablet--column-reverse {
    flex-direction: column-reverse;
  }

  .container-display_tablet--none {
    display: none;
  }
  .container-display_tablet--block {
    display: block;
  }
  .container-display_tablet--inline {
    display: inline;
  }
  .container-display_tablet--inline-block {
    display: inline-block;
  }
  .container-display_tablet--flex {
    display: flex;
  }
  .container-display_tablet--inline-flex {
    display: inline-flex;
  }
  .container-display_tablet--table-row {
    display: table-row;
  }
  .container-display_tablet--table-cell {
    display: table-cell;
  }
  .container-display_tablet--table {
    display: table;
  }
  .container-display_tablet--inline-table {
    display: inline-table;
  }
}

@media (min-width: 1232px) {
  .container-align_items_desktop_max--inherit {
    align-items: inherit;
  }
  .container-align_items_desktop_max--stretch {
    align-items: stretch;
  }
  .container-align_items_desktop_max--center {
    align-items: center;
  }
  .container-align_items_desktop_max--flex-start {
    align-items: flex-start;
  }
  .container-align_items_desktop_max--flex-end {
    align-items: flex-end;
  }
  .container-align_items_desktop_max--baseline {
    align-items: baseline;
  }

  .container-align_content_desktop_max--inherit {
    align-content: inherit;
  }
  .container-align_content_desktop_max--stretch {
    align-content: stretch;
  }
  .container-align_content_desktop_max--center {
    align-content: center;
  }
  .container-align_content_desktop_max--flex-start {
    align-content: flex-start;
  }
  .container-align_content_desktop_max--flex-end {
    align-content: flex-end;
  }
  .container-align_content_desktop_max--space-between {
    align-content: space-between;
  }
  .container-align_content_desktop_max--space-around {
    align-content: space-around;
  }

  .container-white_space_desktop_max--nowrap {
    white-space: nowrap;
  }
  .container-white_space_desktop_max--inherit {
    white-space: inherit;
  }
  .container-white_space_desktop_max--initial {
    white-space: initial;
  }
  .container-white_space_desktop_max--normal {
    white-space: normal;
  }
  .container-white_space_desktop_max--pre {
    white-space: pre;
  }
  .container-white_space_desktop_max--pre-line {
    white-space: pre-line;
  }
  .container-white_space_desktop_max--pre-wrap {
    white-space: pre-wrap;
  }
  .container-white_space_desktop_max--revert {
    white-space: revert;
  }
  .container-white_space_desktop_max--unset {
    white-space: unset;
  }

  .container-align_self_desktop_max--inherit {
    align-self: inherit;
  }
  .container-align_self_desktop_max--stretch {
    align-self: stretch;
  }
  .container-align_self_desktop_max--center {
    align-self: center;
  }
  .container-align_self_desktop_max--flex-start {
    align-self: flex-start;
  }
  .container-align_self_desktop_max--baseline {
    align-self: baseline;
  }
  .container-align_self_desktop_max--auto {
    align-self: auto;
  }

  .container-justify_content_desktop_max--inherit {
    justify-content: inherit;
  }
  .container-justify_content_desktop_max--stretch {
    justify-content: stretch;
  }
  .container-justify_content_desktop_max--center {
    justify-content: center;
  }
  .container-justify_content_desktop_max--flex-start {
    justify-content: flex-start;
  }
  .container-justify_content_desktop_max--flex-end {
    justify-content: flex-end;
  }
  .container-justify_content_desktop_max--baseline {
    justify-content: baseline;
  }
  .container-justify_content_desktop_max--space-between {
    justify-content: space-between;
  }
  .container-justify_content_desktop_max--space-around {
    justify-content: space-around;
  }
  .container-justify_content_desktop_max--space-evenly {
    justify-content: space-evenly;
  }

  .container-flex_wrap_desktop_max--inherit {
    flex-wrap: inherit;
  }
  .container-flex_wrap_desktop_max--nowrap {
    flex-wrap: nowrap;
  }
  .container-flex_wrap_desktop_max--wrap {
    flex-wrap: wrap;
  }
  .container-flex_wrap_desktop_max--wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .container-flex_direction_desktop_max--inherit {
    flex-direction: inherit;
  }
  .container-flex_direction_desktop_max--row {
    flex-direction: row;
  }
  .container-flex_direction_desktop_max--row-reverse {
    flex-direction: row-reverse;
  }
  .container-flex_direction_desktop_max--column-reverse {
    flex-direction: column;
  }
  .container-flex_direction_desktop_max--column-reverse {
    flex-direction: column-reverse;
  }

  .container-display_desktop_max--none {
    display: none;
  }
  .container-display_desktop_max--block {
    display: block;
  }
  .container-display_desktop_max--inline {
    display: inline;
  }
  .container-display_desktop_max--inline-block {
    display: inline-block;
  }
  .container-display_desktop_max--flex {
    display: flex;
  }
  .container-display_desktop_max--inline-flex {
    display: inline-flex;
  }
  .container-display_desktop_max--table-row {
    display: table-row;
  }
  .container-display_desktop_max--table-cell {
    display: table-cell;
  }
  .container-display_desktop_max--table {
    display: table;
  }
  .container-display_desktop_max--inline-table {
    display: inline-table;
  }
}

@media print {
  .container-no_print {
    display: none;
  }
  .container-print_as_block {
    display: block;
  }
  .container-break_inside_avoid {
    page-break-inside: avoid;
  }
}
