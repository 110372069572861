.img {
  margin: 24px auto 42px;
  width: 210px;
  height: 210px;
  background-image: url('./assets/img.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 210px 210px;
}

.footer {
  padding-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
