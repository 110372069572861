.wrapper {
  position: relative;
  display: inline-block;
}

.wrapper:hover .container {
  opacity: 1;
}

.container {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
  opacity: 0;
}

.container--not-covered {
  opacity: 1;
}

.block {
  position: relative;
  padding: 2px 12px 4px;
  display: block;
  box-sizing: content-box;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: white;
  background: #000000e6;
  border-radius: 2px;
}

.block--error {
  color: white;
  background: var(--color_red);
}

.content {
  overflow: hidden;
  line-height: 22px;
  white-space: nowrap;
}

.bottom_center {
  right: 50%;
  transform: translateX(50%);
}
