.container {
  z-index: 3;
  padding: 24px 0;
}

.links {
  display: flex;
  background: var(--decorative-theme-white);
  border-radius: 4px;
}

@media print {
  .container {
    display: none;
  }
}
