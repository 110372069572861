.container {
  position: relative;
}

.suggest-item {
  padding: 8px 54px 8px 40px;
  font-size: 14px;
  cursor: pointer;
  color: #121212;
}

.suggest-item__selected {
  background-color: #eaf3fb;
}

.undergrounds {
  position: absolute;
  top: 10px;
  left: 32px;
  padding: 4px;
  display: flex;
  flex-direction: row-reverse;
  transform: translate(-100%, 0);
}

.underground-item {
  margin-right: -4px;
  display: block;
  overflow: hidden;
  width: 8px;
  height: 8px;
  border: 1px solid white;
  border-radius: 8px;
}

.link {
  position: absolute;
  top: 8px;
  right: 36px;
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEyIDEyIiB3aWR0aD0iMTIiIGhlaWdodD0iMTIiPjxwYXRoIGZpbGw9IiMyQjg3REIiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTEyIC41VjVoLTFWMUg3VjBoNXYuNXpNMCAxaDF2MTBIMFYxem0xIDExdi0xaDEwdjFIMXpNNSAwdjFIMVYwaDR6bTcgMTFoLTFWN2gxdjR6TTEwIDFoMXYxaC0xVjF6TTkgMmgxdjFIOVYyek04IDNoMXYxSDhWM3pNNyA0aDF2MUg3VjR6TTYgNWgxdjFINlY1eiIvPjwvc3ZnPg==')
    50% no-repeat;
  border-radius: 4px;
}

.link:hover {
  background-color: #cce2f7;
}
