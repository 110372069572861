.container-display--none {
  display: none;
}
.container-display--block {
  display: block;
}
.container-display--inline {
  display: inline;
}
.container-display--inline-block {
  display: inline-block;
}
.container-display--flex {
  display: flex;
}
.container-display--inline-flex {
  display: inline-flex;
}

.container-width--auto {
  width: auto;
}
.container-width--inherit {
  width: inherit;
}
.container-width--wide {
  width: 100%;
}

.element {
  display: inline-flex;
  align-items: center;
}

.element-reverse {
  flex-direction: row-reverse;
}

.offset {
  height: 1px;
}

.icon {
  padding-top: 2px;
}

@media (max-width: 991px) {
  .container-display_mobile--none {
    display: none;
  }
  .container-display_mobile--block {
    display: block;
  }
  .container-display_mobile--inline {
    display: inline;
  }
  .container-display_mobile--inline-block {
    display: inline-block;
  }
  .container-display_mobile--flex {
    display: flex;
  }
  .container-display_mobile--inline-flex {
    display: inline-flex;
  }
}

@media (max-width: 639px) {
  .container-display_phone--none {
    display: none;
  }
  .container-display_phone--block {
    display: block;
  }
  .container-display_phone--inline {
    display: inline;
  }
  .container-display_phone--inline-block {
    display: inline-block;
  }
  .container-display_phone--flex {
    display: flex;
  }
  .container-display_phone--inline-flex {
    display: inline-flex;
  }
}

@media (min-width: 640px) and (min-width: 991px) {
  .container-display_tablet--none {
    display: none;
  }
  .container-display_tablet--block {
    display: block;
  }
  .container-display_tablet--inline {
    display: inline;
  }
  .container-display_tablet--inline-block {
    display: inline-block;
  }
  .container-display_tablet--flex {
    display: flex;
  }
  .container-display_tablet--inline-flex {
    display: inline-flex;
  }
}

@media (min-width: 1232px) {
  .container-display_desktop_max--none {
    display: none;
  }
  .container-display_desktop_max--block {
    display: block;
  }
  .container-display_desktop_max--inline {
    display: inline;
  }
  .container-display_desktop_max--inline-block {
    display: inline-block;
  }
  .container-display_desktop_max--flex {
    display: flex;
  }
  .container-display_desktop_max--inline-flex {
    display: inline-flex;
  }
}
