.container {
  position: relative;
  z-index: 0;
  margin-left: -20px;
  padding: 24px 20px;
  display: flex;
  width: calc(100% + 40px);
  min-height: 365px;
  background-color: white;

  &:hover {
    z-index: 2;
    border-radius: var(--unit_2);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);

    &::after {
      display: none;
    }
  }

  &::after {
    position: absolute;
    z-index: 1;
    right: 20px;
    left: 20px;
    bottom: 0;
    height: 1px;
    content: '';
    background: var(--gray10_100);
  }
}

.preview {
  margin-right: 16px;
}

.reviews {
  margin-right: 4px;
  margin-bottom: -10px;
}

.content {
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 12px;
}

.other {
  margin-bottom: 12px;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.labels {
  margin-bottom: 12px;
  display: flex;
  gap: 8px;
}

.labels:empty {
  display: none;
}

.status {
  margin-right: 18px;
  display: inline-block;
}

.address {
  margin-bottom: 14px;
}

.advertises {
  margin-bottom: 12px;
}

.footer {
  margin-top: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.call {
  margin-right: 8px;
}

.message:not(:empty) ~ .rooms {
  margin-top: 16px;
  display: block;
  width: 100%;
}

.message:not(:empty) ~ .favorite {
  margin-right: 0;
}

.favorite {
  margin-right: 16px;
}
