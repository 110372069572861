.undergrounds {
  padding-right: 4px;
  display: flex;
  flex-direction: row-reverse;
}

.undergrounds__item {
  margin-right: -4px;
  display: block;
  overflow: hidden;
  width: 8px;
  height: 8px;
  border: 1px solid white;
  border-radius: 8px;
}
