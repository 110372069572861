.field-row {
  padding: 20px 28px 0;
  display: flex;
  max-width: 860px;
  column-gap: 40px;
}

.field {
  width: 100%;
}
