.layout {
  margin: 0 auto;
  padding: 20px;
  display: grid;
  box-sizing: border-box;
  min-width: 704px;
  max-width: 1376px;
  grid-template-columns: 100%;
}

@media (max-width: 991px) {
  .layout {
    padding-right: 12px;
    padding-left: 12px;
  }
}

@media print {
  .layout {
    margin: 0 1px;
    padding: 0;
    max-width: 100%;
  }
}
