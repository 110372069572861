.container {
  position: fixed;
  z-index: 5;
  right: 20px;
  bottom: 20px;
}

.button {
  all: unset;
  display: block;
  cursor: pointer;
  outline: 1px solid rgba(255, 255, 255, 0.4);
}

.content {
  display: block;
  overflow: hidden;
  width: 50px;
  height: 50px;
  font-size: 0;
  background-color: var(--accent-main-primary);
  background-image: url('./assets/chat.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px 24px;
  border-radius: 50px;
}
