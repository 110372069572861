.suggest_list {
  position: absolute;
  z-index: 10;
  inset: 0;
  background-color: white;
  border-radius: 4px;
}

@media (min-width: 1025px) {
  .suggest_list {
    inset: 8px auto auto;
    overflow-y: scroll;
    width: 400px;
    max-height: 364px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
  }
}

@media (min-width: 1025px) {
  .suggest_list_handler {
    position: relative;
    min-width: 272px;
  }
}

.suggest_list_input_handler {
  position: relative;
  padding: 16px 14% 4px 10px;
}

@media (min-width: 1025px) {
  .suggest_list_input_handler {
    display: none;
  }
}

.suggest_list_close_icon {
  position: absolute;
  top: 16px;
  right: 10px;
  cursor: pointer;
  color: var(--gray40_100);
}

.suggest_list_close_icon:hover {
  color: var(--gray60_100);
}
