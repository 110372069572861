.container {
  position: relative;
  margin: 0 calc(var(--unit_5, 20px) * -1);
  padding: var(--unit_5, 20px);
  display: grid;
  text-decoration: none;
  background-color: white;
  grid-template-columns: 272px 1fr 184px 28px;
  grid-column-gap: var(--unit_2, 8px);

  &:hover {
    z-index: 2;
    border-radius: var(--unit_2, 8px);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  }

  &:not(:hover) {
    &::after {
      position: absolute;
      z-index: 1;
      right: var(--unit_5, 20px);
      left: var(--unit_5, 20px);
      bottom: -1px;
      height: 1px;
      content: '';
      background-color: var(--gray10_100);
    }
  }
}

.photo-block,
.main-content-block {
  max-height: 206px;
}

.photo-block {
  position: relative;
}

.photo-label {
  position: absolute;
  z-index: 1;
  top: var(--unit_4, 16px);
  left: var(--unit_4, 16px);
}

.main-content-block {
  padding: 0 var(--unit_3, 12px);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  row-gap: var(--unit_4, 16px);
}

.content-container {
  display: grid;
  grid-row-gap: var(--unit_4, 16px);
}

.transports-container {
  display: flex;
  flex-direction: column;
  row-gap: var(--unit_0, 2px);

  &:empty {
    display: none;
  }
}

.ad-info {
  margin-top: var(--unit_4, 16px);
  display: flex;
  overflow: hidden;
  overflow-y: scroll;
  flex-direction: column;
  max-height: 82px;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.builder-name-wrapper {
  margin-top: var(--unit_2, 8px);
}

.description-wrapper {
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  overflow: hidden;
  -webkit-box-orient: vertical;
  max-height: calc(var(--unit_5, 20px) * 4);
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
}

.empty {
  margin-bottom: 0;
}

.close-block {
  display: flex;
  justify-content: flex-end;
}
