.field-title {
  flex: none;
  display: flex;
  width: 140px;

  &.center {
    align-items: center;
  }

  &.top {
    align-items: flex-start;
  }
}
