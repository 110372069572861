.container {
  height: 24px;
}

.feature-container {
  margin-right: 4px;
  display: inline-block;
}

.feature {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 24px;
  height: 24px;
  text-align: center;
  background-color: var(--black_60);
  border-radius: 4px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.6);
  }
}

.feature_wrapper {
  margin-right: 8px;
}

.feature-icon-wrapper {
  position: relative;
  z-index: 1;
  height: 16px;
}

.feature_icon {
  position: absolute;
  inset: 0;
  margin: auto;
  width: 18px;
  height: auto;
}

.feature--video .feature_icon {
  width: 16px;
}
