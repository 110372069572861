.container {
  margin-bottom: 12px;
  padding: 20px 24px;
  display: flex;
  align-items: center;
  background: var(--decorative-theme-white);
  border-radius: 4px;
  box-shadow: none;
  transition: box-shadow 0.3s linear;
}

.container:hover {
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
}

.icon {
  flex: none;
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.content {
  flex: auto;
}

.button {
  flex: none;
}

.media {
  margin-right: 16px;
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  background: rgba(135, 41, 255, 0.1);
  border-radius: 4px;
}
