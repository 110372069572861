@import '../../styles/common/colors.css';

.hidden-select-handler {
  position: relative;
  display: inline-block;
}

.hidden-select {
  position: absolute;
  z-index: 1;
  inset: 0;
  display: none;
  width: 100%;
  height: 100%;
  color: transparent;
  background-color: transparent;
  border: 0;
  outline: none;
}

.hidden-select-handler .select-button-clicked {
  background-color: var(--color_dark_light_1);
}

.hidden-select-handler .select-button-clicked:focus {
  outline: 0;
  box-shadow: none;
}

.group {
  display: inline-flex;
  flex-direction: row;
}

.group > [data-style='select_component'] button:hover {
  z-index: 1;
}

.group > [data-style='select_component'] button:focus {
  z-index: 2;
}

.group > [data-style='select_component']:not(:last-child) button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.group > [data-style='select_component']:not(:first-child) button {
  /* для наложения бордера */
  margin-left: -1px;
  padding-left: 12px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* Тк данное решение не меняет цвет границ кнопки */
/* stylelint-disable selector-max-specificity */
.group > [data-style='select_component']:not(:last-child):not(:first-child) button {
  padding-left: 12px;
  border-radius: 0;
}
/* stylelint-enable selector-max-specificity */

.list {
  overflow-y: scroll;
  max-height: 270px;
  background-color: var(--color_white);
  border: solid 1px var(--color_gray_0);
  border-radius: 4px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}

.arrow_icon {
  margin: auto;
  width: 12px;
  height: 12px;
  line-height: 12px;
}

.arrow_icon svg {
  transform: rotate(90deg);
}

.arrow_icon__open svg {
  transform: rotate(-90deg);
}
