@import '../../styles/common/colors.css';

.field {
  position: relative;
}

.label {
  margin-bottom: 8px;
  height: 18px;
}

.input {
  padding: 3px 8px 5px;
  width: 100%;
  height: 28px;
  font-size: 14px;
  line-height: 1.43;
  color: var(--color_black);
  background-color: var(--color_white);
  border: solid 1px var(--color_gray_2);
  border-radius: 4px;
  outline: none;
}

/* Это специфичные для браузера свойства */
/* stylelint-disable property-no-vendor-prefix */
.input[type='number']::-webkit-inner-spin-button,
.input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input[type='number'] {
  -moz-appearance: textfield;
}
/* stylelint-enable property-no-vendor-prefix */

.input::placeholder {
  color: var(--color_gray_3);
}

/* Плейсхолдер для IE11 */
/* stylelint-disable selector-no-vendor-prefix */
.input:-ms-input-placeholder {
  color: var(--color_gray_3);
}
/* stylelint-enable selector-no-vendor-prefix */

.input:not(:disabled):hover {
  border-color: var(--color_gray_4);
}

.input:focus,
.input:not(:disabled):active {
  border-color: var(--color_black);
}

.input:disabled {
  cursor: not-allowed;
  background-color: var(--color_gray_0);
}

.invalid .input,
.invalid .input:hover,
.invalid .input:focus {
  border-color: var(--color_red);
}

.sync .input {
  padding-right: 32px;
}

.loader {
  position: absolute;
  right: 8px;
  bottom: 2px;
}
