.features-list {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
}

.feature-title {
  margin-bottom: 2px;
}

.feature-item:not(:first-child) {
  margin-left: 24px;
}

@media screen and (min-width: 641px) {
  .feature-item:not(:first-child) {
    margin-left: 40px;
  }
}
