.container {
  margin-bottom: 8px;
}

.title {
  display: flex;
  align-items: center;
  gap: 12px;
}

.content {
  display: inline-block;
  vertical-align: middle;
}

.description {
  display: inline-block;
  vertical-align: middle;
}

.sales-start {
  display: inline-block;
  vertical-align: middle;
  text-indent: 0.2em;
}
