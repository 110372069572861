@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.container {
  position: fixed;
  z-index: 10;
  right: 20px;
  bottom: 0;
  overflow: hidden;
  width: 360px;
  height: 640px;
  background: white;
  border: 1px solid var(--gray10_100);
  border-radius: 4px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
}

.iframe {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
}

.close {
  position: absolute;
  top: 14px;
  right: 14px;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
