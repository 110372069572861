@import '../../styles/common/colors.css';

.carousel {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  user-select: none;
  border-radius: 4px;
}

.carousel-left_arrow,
.carousel-right_arrow {
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: none;
  border: none;
  outline: none;
  opacity: 0;
  transition: opacity 0.2s;
  transform: translate3d(0, 0, 0);
}

.carousel:hover .carousel-left_arrow,
.carousel:hover .carousel-right_arrow,
.carousel-left_arrow:focus,
.carousel-right_arrow:focus {
  opacity: 1;
}

@media (hover: none) {
  .carousel-left_arrow,
  .carousel-right_arrow {
    opacity: 1;
  }
}

.carousel-left_arrow--disabled,
.carousel-right_arrow--disabled {
  display: none;
}

.carousel-left_arrow > .carousel-icon,
.carousel-right_arrow > .carousel-icon {
  display: flex;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-color: var(--color_black);
  border-radius: 100%;
  opacity: 0.3;
  justify-content: center;
  align-items: center;
}

.carousel-left_arrow > .carousel-icon:hover,
.carousel-right_arrow > .carousel-icon:hover {
  opacity: 0.7;
}
.carousel-left_arrow {
  left: 8px;
}

.carousel-right_arrow {
  right: 8px;
}

.carousel-counter {
  position: absolute;
  z-index: 1;
  right: 8px;
  bottom: 8px;
  padding: 4px 4px 5px 5px;
  height: 24px;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  color: var(--color_white);
  background-color: var(--black_60);
  border-radius: 4px;
}

.carousel-slide {
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  transition: left 0.3s;
  transform: translate3d(0, 0, 0);
}

.carousel-slide--left,
.carousel-slide--current,
.carousel-slide--right {
  z-index: 1;
  display: block;
}

.carousel-slide--left {
  left: -100%;
}

.carousel-slide--current {
  left: 0;
}

.carousel-slide--right {
  left: 100%;
}

.carousel-slide--hide {
  opacity: 0;
}

.carousel--promo {
  .carousel-counter {
    right: 24px;
    bottom: 24px;
  }

  .carousel-left_arrow > .carousel-icon,
  .carousel-right_arrow > .carousel-icon {
    width: 28px;
    height: 28px;
    color: var(--icon-secondary-default);
    background-color: var(--decorative-theme-white);
    opacity: 1;
  }

  .carousel-left_arrow {
    left: 18px;
  }

  .carousel-right_arrow {
    right: 18px;
  }
}

@media print {
  .carousel-counter {
    display: none;
  }
}
