@import '../../styles/common/colors.css';

.element {
  font-family:
    Lato,
    -apple-system,
    Helvetica,
    Arial,
    sans-serif;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  transition: color 0.3s ease-out;
}

.element p {
  margin: 0;
}
.element-type--small {
  font-size: 12px;
  line-height: 18px;
}

.element-type--normal {
  font-size: 14px;
  line-height: 20px;
}

.element-type--big {
  font-size: 38px;
  line-height: 22px;
}

.element-type--h1 {
  font-size: 28px;
  line-height: 36px;
}

.element-type--h2 {
  font-size: 22px;
  line-height: 28px;
}

.element-type--h3 {
  font-size: 18px;
  line-height: 24px;
}

.element-font_size--0 {
  font-size: 0;
}
.element-font_size--10 {
  font-size: 10px;
}
.element-font_size--12 {
  font-size: 12px;
}
.element-font_size--14 {
  font-size: 14px;
}
.element-font_size--16 {
  font-size: 16px;
}
.element-font_size--18 {
  font-size: 18px;
}
.element-font_size--22 {
  font-size: 22px;
}
.element-font_size--28 {
  font-size: 28px;
}
.element-font_size--36 {
  font-size: 36px;
}

.element-line_height--normal {
  line-height: normal;
}
.element-line_height--0 {
  line-height: 0;
}
.element-line_height--12 {
  line-height: 12px;
}
.element-line_height--14 {
  line-height: 14px;
}
.element-line_height--16 {
  line-height: 16px;
}
.element-line_height--18 {
  line-height: 18px;
}
.element-line_height--20 {
  line-height: 20px;
}
.element-line_height--22 {
  line-height: 22px;
}
.element-line_height--24 {
  line-height: 24px;
}
.element-line_height--28 {
  line-height: 28px;
}
.element-line_height--36 {
  line-height: 36px;
}
.element-line_height--46 {
  line-height: 46px;
}

.element-color--transparent {
  color: transparent;
}
.element-color--black {
  color: var(--color_black);
}
.element-color--white {
  color: var(--color_white);
}
.element-color--gray_0 {
  color: var(--color_gray_0);
}
.element-color--gray_1 {
  color: var(--color_gray_1);
}
.element-color--gray_2 {
  color: var(--color_gray_2);
}
.element-color--gray_3 {
  color: var(--color_gray_3);
}
.element-color--gray_4 {
  color: var(--color_gray_4);
}
.element-color--gray_6 {
  color: var(--color_gray_6);
}
.element-color--blue {
  color: var(--color_blue);
}
.element-color--blue_hover {
  color: var(--color_blue_hover);
}
.element-color--blue_2 {
  color: var(--color_blue_2);
}
.element-color--pink {
  color: var(--color_pink);
}
.element-color--green {
  color: var(--color_green);
}
.element-color--green_darken {
  color: var(--color_green_darken);
}
.element-color--red {
  color: var(--color_red);
}
.element-color--red_1 {
  color: var(--color_red_1);
}
.element-color--orange {
  color: var(--color_orange);
}
.element-color--yellow {
  color: var(--color_yellow);
}

.element-color--transparent * {
  color: transparent;
}
.element-color--black * {
  color: var(--color_black);
}
.element-color--white * {
  color: var(--color_white);
}
.element-color--gray_0 * {
  color: var(--color_gray_0);
}
.element-color--gray_1 * {
  color: var(--color_gray_1);
}
.element-color--gray_2 * {
  color: var(--color_gray_2);
}
.element-color--gray_3 * {
  color: var(--color_gray_3);
}
.element-color--gray_4 * {
  color: var(--color_gray_4);
}
.element-color--blue * {
  color: var(--color_blue);
}
.element-color--blue_hover * {
  color: var(--color_blue_hover);
}
.element-color--pink * {
  color: var(--color_pink);
}
.element-color--green * {
  color: var(--color_green);
}
.element-color--green_darken * {
  color: var(--color_green_darken);
}
.element-color--red * {
  color: var(--color_red);
}
.element-color--red_1 * {
  color: var(--color_red_1);
}
.element-color--orange * {
  color: var(--color_orange);
}
.element-color--yellow * {
  color: var(--color_yellow);
}

.element-color_hovered--transparent:hover {
  color: transparent;
}
.element-color_hovered--black:hover {
  color: var(--color_black);
}
.element-color_hovered--white:hover {
  color: var(--color_white);
}
.element-color_hovered--gray_0:hover {
  color: var(--color_gray_0);
}
.element-color_hovered--gray_1:hover {
  color: var(--color_gray_1);
}
.element-color_hovered--gray_2:hover {
  color: var(--color_gray_2);
}
.element-color_hovered--gray_3:hover {
  color: var(--color_gray_3);
}
.element-color_hovered--gray_4:hover {
  color: var(--color_gray_4);
}
.element-color_hovered--blue:hover {
  color: var(--color_blue);
}
.element-color_hovered--blue_2:hover {
  color: var(--color_blue_2);
}
.element-color_hovered--blue_hover:hover {
  color: var(--color_blue_hover);
}
.element-color_hovered--pink:hover {
  color: var(--color_pink);
}
.element-color_hovered--green:hover {
  color: var(--color_green);
}
.element-color_hovered--green_darken:hover {
  color: var(--color_green_darken);
}
.element-color_hovered--red:hover {
  color: var(--color_red);
}
.element-color_hovered--red_1:hover {
  color: var(--color_red_1);
}
.element-color_hovered--orange:hover {
  color: var(--color_orange);
}
.element-color_hovered--yellow:hover {
  color: var(--color_yellow);
}

.element-color_hovered--transparent:hover * {
  color: transparent;
}
.element-color_hovered--black:hover * {
  color: var(--color_black);
}
.element-color_hovered--white:hover * {
  color: var(--color_white);
}
.element-color_hovered--gray_0:hover * {
  color: var(--color_gray_0);
}
.element-color_hovered--gray_1:hover * {
  color: var(--color_gray_1);
}
.element-color_hovered--gray_2:hover * {
  color: var(--color_gray_2);
}
.element-color_hovered--gray_3:hover * {
  color: var(--color_gray_3);
}
.element-color_hovered--gray_4:hover * {
  color: var(--color_gray_4);
}
.element-color_hovered--blue:hover * {
  color: var(--color_blue);
}
.element-color_hovered--blue_hover:hover * {
  color: var(--color_blue_hover);
}
.element-color_hovered--pink:hover * {
  color: var(--color_pink);
}
.element-color_hovered--green:hover * {
  color: var(--color_green);
}
.element-color_hovered--green_darken:hover * {
  color: var(--color_green_darken);
}
.element-color_hovered--red:hover * {
  color: var(--color_red);
}
.element-color_hovered--red_1:hover * {
  color: var(--color_red_1);
}
.element-color_hovered--orange:hover * {
  color: var(--color_orange);
}
.element-color_hovered--yellow:hover * {
  color: var(--color_yellow);
}

.element-display--none {
  display: none;
}
.element-display--block {
  display: block;
}
.element-display--inline {
  display: inline;
}
.element-display--inline-block {
  display: inline-block;
}
.element-display--flex {
  display: flex;
}
.element-display--inline-flex {
  display: inline-flex;
}

.element-width--auto {
  width: auto;
}
.element-width--inherit {
  width: inherit;
}
.element-width--wide {
  width: 100%;
}

.element-visibility--visible {
  visibility: visible;
}
.element-visibility--hidden {
  visibility: hidden;
}
.element-visibility--inherit {
  visibility: inherit;
}

.element-vertical_align--middle {
  vertical-align: middle;
}
.element-vertical_align--bottom {
  vertical-align: bottom;
}
.element-vertical_align--top {
  vertical-align: top;
}

.element-text_decoration--inherit {
  text-decoration: inherit;
}
.element-text_decoration--none {
  text-decoration: none;
}
.element-text_decoration--underline {
  text-decoration: underline;
}
.element-text_decoration--overline {
  text-decoration: overline;
}
.element-text_decoration--line-through {
  text-decoration: line-through;
}

.element-text_transform--uppercase {
  text-transform: uppercase;
}

.element-text_align--inherit {
  text-align: inherit;
}
.element-text_align--left {
  text-align: left;
}
.element-text_align--right {
  text-align: right;
}
.element-text_align--center {
  text-align: center;
}
.element-text_align--justify {
  text-align: justify;
}

.element-overflow--inherit {
  overflow: inherit;
}
.element-overflow--auto {
  overflow: auto;
}
.element-overflow--visible {
  overflow: visible;
}
.element-overflow--hidden {
  overflow: hidden;
}
.element-overflow--scroll {
  overflow: scroll;
}

.element-word_wrap--inherit {
  word-wrap: inherit;
}
.element-word_wrap--normal {
  word-wrap: normal;
}
.element-word_wrap--break-word {
  word-wrap: break-word;
}

.element-white_space--inherit {
  white-space: inherit;
}
.element-white_space--normal {
  white-space: normal;
}
.element-white_space--nowrap {
  white-space: nowrap;
}
.element-white_space--pre {
  white-space: pre;
}
.element-white_space--pre-wrap {
  white-space: pre-wrap;
}
.element-white_space--pre-line {
  white-space: pre-line;
}

.element-font_weight--normal {
  font-weight: normal;
}
.element-font_weight--bold {
  font-weight: bold;
}
.element-font_weight--inherit {
  font-weight: inherit;
}

.element-border_box {
  box-sizing: border-box;
}

.element-ellipsis {
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}

.element-row_ellipsis {
  display: block;
  /* stylelint-disable value-no-vendor-prefix */
  display: -webkit-box;
  /* stylelint-enable value-no-vendor-prefix */
  overflow: hidden;
  max-width: 100%;
  height: 100%;
  text-overflow: ellipsis;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* stylelint-enable property-no-vendor-prefix */
}

@media (max-width: 991px) {
  .element-type--small {
    font-size: 14px;
    line-height: 18px;
  }

  .element-type--normal {
    font-size: 16px;
    line-height: 22px;
  }

  .element-display_mobile--none {
    display: none;
  }
  .element-display_mobile--block {
    display: block;
  }
  .element-display_mobile--inline {
    display: inline;
  }
  .element-display_mobile--inline-block {
    display: inline-block;
  }
  .element-display_mobile--flex {
    display: flex;
  }
  .element-display_mobile--inline-flex {
    display: inline-flex;
  }

  .element-font_size_mobile--0 {
    font-size: 0;
  }
  .element-font_size_mobile--10 {
    font-size: 10px;
  }
  .element-font_size_mobile--12 {
    font-size: 12px;
  }
  .element-font_size_mobile--14 {
    font-size: 14px;
  }
  .element-font_size_mobile--16 {
    font-size: 16px;
  }
  .element-font_size_mobile--18 {
    font-size: 18px;
  }
  .element-font_size_mobile--22 {
    font-size: 22px;
  }
  .element-font_size_mobile--28 {
    font-size: 28px;
  }
  .element-font_size_mobile--36 {
    font-size: 36px;
  }

  .element-line_height_mobile--0 {
    line-height: 0;
  }
  .element-line_height_mobile--12 {
    line-height: 12px;
  }
  .element-line_height_mobile--14 {
    line-height: 14px;
  }
  .element-line_height_mobile--16 {
    line-height: 16px;
  }
  .element-line_height_mobile--18 {
    line-height: 18px;
  }
  .element-line_height_mobile--20 {
    line-height: 20px;
  }
  .element-line_height_mobile--22 {
    line-height: 22px;
  }
  .element-line_height_mobile--24 {
    line-height: 24px;
  }
  .element-line_height_mobile--28 {
    line-height: 28px;
  }
  .element-line_height_mobile--36 {
    line-height: 36px;
  }
  .element-line_height_mobile--46 {
    line-height: 46px;
  }
}

@media (max-width: 639px) {
  .element-display_phone--none {
    display: none;
  }
  .element-display_phone--block {
    display: block;
  }
  .element-display_phone--inline {
    display: inline;
  }
  .element-display_phone--inline-block {
    display: inline-block;
  }
  .element-display_phone--flex {
    display: flex;
  }
  .element-display_phone--inline-flex {
    display: inline-flex;
  }
}

@media (min-width: 640px) {
  .element-display_tablet--none {
    display: none;
  }
  .element-display_tablet--block {
    display: block;
  }
  .element-display_tablet--inline {
    display: inline;
  }
  .element-display_tablet--inline-block {
    display: inline-block;
  }
  .element-display_tablet--flex {
    display: flex;
  }
  .element-display_tablet--inline-flex {
    display: inline-flex;
  }
}

@media (min-width: 992px) {
  .element-font_size_desktop--0 {
    font-size: 0;
  }
  .element-font_size_desktop--10 {
    font-size: 10px;
  }
  .element-font_size_desktop--12 {
    font-size: 12px;
  }
  .element-font_size_desktop--14 {
    font-size: 14px;
  }
  .element-font_size_desktop--16 {
    font-size: 16px;
  }
  .element-font_size_desktop--18 {
    font-size: 18px;
  }
  .element-font_size_desktop--22 {
    font-size: 22px;
  }
  .element-font_size_desktop--28 {
    font-size: 28px;
  }
  .element-font_size_desktop--36 {
    font-size: 36px;
  }

  .element-line_height_desktop--0 {
    line-height: 0;
  }
  .element-line_height_desktop--12 {
    line-height: 12px;
  }
  .element-line_height_desktop--14 {
    line-height: 14px;
  }
  .element-line_height_desktop--16 {
    line-height: 16px;
  }
  .element-line_height_desktop--18 {
    line-height: 18px;
  }
  .element-line_height_desktop--20 {
    line-height: 20px;
  }
  .element-line_height_desktop--22 {
    line-height: 22px;
  }
  .element-line_height_desktop--24 {
    line-height: 24px;
  }
  .element-line_height_desktop--28 {
    line-height: 28px;
  }
  .element-line_height_desktop--36 {
    line-height: 36px;
  }
  .element-line_height_desktop--46 {
    line-height: 46px;
  }
}

@media (min-width: 1232px) {
  .element-display_desktop_max--none {
    display: none;
  }
  .element-display_desktop_max--block {
    display: block;
  }
  .element-display_desktop_max--inline {
    display: inline;
  }
  .element-display_desktop_max--inline-block {
    display: inline-block;
  }
  .element-display_desktop_max--flex {
    display: flex;
  }
  .element-display_desktop_max--inline-flex {
    display: inline-flex;
  }
}
