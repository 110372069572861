.container {
  margin-bottom: 12px;
  padding: 20px 24px;
  display: flex;
  align-items: center;
  background: var(--decorative-theme-white);
  border-radius: 4px;
}

.icon {
  margin-right: 16px;
  flex: none;
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.content {
  flex: auto;
}
