.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 132px;
  background: #f1f2f4;
  border-radius: 4px;
}

.content {
  padding: 16px;
  width: 100%;
}

.text {
  margin-bottom: 12px;
  width: 230px;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
}

.image {
  display: none;
}

@media screen and (min-width: 1025px) {
  .image {
    display: block;
    width: 174px;
    min-width: 174px;
    height: 100%;
    background-image: url('assets/image.png');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain;
  }
}
