.container {
  padding: 16px 20px 24px;
}

.title {
  margin-bottom: 24px;
}

.features-list:not(:last-child) {
  margin-bottom: 24px;
}

.description {
  margin: 0;
  font-size: var(--fontSize_16px);
  font-weight: normal;
  line-height: var(--lineHeight_24px);
  white-space: pre-line;
}

.internal-banner,
.newbuilding-link {
  margin-top: 32px;
}

@media screen and (min-width: 641px) {
  .container {
    padding: 16px 40px 40px;
  }

  .title {
    margin-bottom: 16px;
  }

  .features-list:not(:last-child) {
    margin-bottom: 20px;
  }

  .description {
    line-height: var(--lineHeight_22px);
  }
}
