.container {
  font-size: var(--lineHeight_28px);
  font-weight: bold;
  text-decoration: none;
  color: var(--decorative-theme-dark);

  &:hover * {
    color: var(--accent-main-primary);
  }
}
