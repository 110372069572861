.container {
  padding-top: 24px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  width: 100%;
  background-color: white;
  gap: 16px;
}

.links {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  overflow: hidden;
  height: 28px;
}

.links--expanded {
  height: auto;
}

.links-block {
  display: flex;
  gap: 16px;
}

.counter {
  color: var(--text-primary-disabled);
}

.link {
  display: inline-flex;
  gap: 4px;
  text-decoration: none;
  flex-shrink: 0;
  cursor: pointer;
  align-items: center;
}
