.container {
  display: flex;
  width: 100%;
  text-decoration: none;

  & > div {
    margin-bottom: 14px;
    width: calc(100% / 4);

    @media (max-width: 1240px) {
      width: calc(100% / 3);

      &:last-child {
        display: none;
      }

      &:nth-child(3) {
        text-align: right;
      }
    }
  }
}

.room {
  color: var(--accent-main-primary);
}

.count {
  text-align: right;
  color: var(--gray60_100);
}

.price {
  color: var(--decorative-theme-dark);
}

.area {
  color: var(--gray60_100);
}
