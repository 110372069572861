.container {
  display: inline-block;
}

.portal {
  position: absolute;
  z-index: 101;
  top: 0;
  left: 0;
}
